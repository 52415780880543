/**
 * We display this message this to block the feed and search page when users have an expired subscription.
 */

import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import ImgFeed from '@pkgs/shared-client/img/upgrade/feed-blur.png';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import { ValueOf } from 'next/dist/shared/lib/constants';

export default function SVSubscriptionExpiredNotice({
	source,
}: {
	source: ValueOf<typeof UpgradeButtonSource>;
}) {
	return (
		<>
			<div className="absolute inset-0 z-0">
				<img src={ImgFeed} alt="" className="h-full w-full object-cover" />
			</div>
			<div className="relative flex min-h-[calc(100vh-var(--nav-height)*2)] flex-col items-center justify-center">
				<div className="relative z-10 flex max-w-80 flex-col items-center justify-center space-y-5 text-center">
					<p className="type-subtitle mb-4 text-gray-50">Go Premium, Stay Inspired</p>
					<p className="type-small text-gray-400">
						Savee runs on the support of users like you.
					</p>
					<p className="type-small text-gray-400">
						When vou reactivate Premium, vou're not just. unlocking unlimited
						inspiration - you're helping us stay independent, ad-free, and entirely
						focused on building a space that puts creativity first.
					</p>
					<p className="type-small text-gray-400">
						Your subscription fuels everything we do - from curating beautiful content
						to launching new features we know you'll love.
					</p>
					<p className="type-small text-gray-400">We'd love to have you back.</p>

					<div>
						<SVTrackedUpgradeButtonContainer
							className="mt-6 bg-gray-200"
							source={source}
							to="/upgrade/"
							use={SVButtonUSES.PRIMARY}
							size={SVButtonSIZES.SMALL}
						>
							Support Savee Again
						</SVTrackedUpgradeButtonContainer>
					</div>
				</div>
				<div className="type-label -sm:bottom-0 absolute -bottom-14 flex text-gray-500">
					Read{' '}
					<SVLink
						to="https://inspire.savee.it/why-savee-doesnt-have-a-free-plan/"
						className="type-label ml-1 underline"
					>
						{' '}
						why we don't have a free plan.
					</SVLink>
				</div>
			</div>
		</>
	);
}
